<template>
  <div id="message">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />消息通知
        </van-col>
        <van-col
          span="14"
          v-if="query.notification_type == '1,2,5'"
          style="text-align: right"
          @click="onIssueClick"
        >
          <svg-icon :data_iconName="'edit'" :className="'edit-icon'" />
        </van-col>
        <van-col
          span="14"
          v-if="query.notification_type == '4'"
          style="text-align: right"
          @click="onDailyWeeklyClick"
        >
          <svg-icon :data_iconName="'edit'" :className="'edit-icon'" />
        </van-col>
      </van-row>
      <van-row class="tabs">
        <van-col
          span="6"
          v-for="(item, index) in tabs"
          :key="index"
          :class="[
            item.type == query.notification_type
              ? 'tab-item-active'
              : 'tab-item',
          ]"
          @click="onTabClick(item)"
          >{{ item.text }}</van-col
        >
      </van-row>
    </div>

    <div class="message-container" v-if="query.notification_type == '3'">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_notifications"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            @click="onIssueItemClick(item)"
            :class="[item.relation.is_read ? 'issue-item-read' : 'issue-item']"
          >
            <van-row>
              <van-col span="14">
                <div
                  :class="[
                    item.relation.is_read
                      ? 'issue-item-title-read van-ellipsis'
                      : 'issue-item-title van-ellipsis',
                  ]"
                >
                  {{
                    item.notification.title
                      ? item.notification.title
                      : "没有标题"
                  }}
                </div></van-col
              >
              <van-col span="10">
                <div
                  :class="[
                    item.relation.is_read
                      ? 'issue-item-date text-read'
                      : 'issue-item-date',
                  ]"
                >
                  {{ item.notification.created_at }}
                </div>
              </van-col>
            </van-row>
            <div
              v-if="!item.relation.is_read"
              :class="[item.relation.is_read ? 'dot background-read' : 'dot']"
            ></div>
          </div>
        </van-list>
        <van-empty
          v-if="list.length == 0 && loading == false"
          description="暂无数据"
        />
      </van-pull-refresh>
    </div>
    <div class="message-container" v-if="query.notification_type == '4'">
      <van-row style="color: #fff; font-size: 0.3rem; margin-bottom: 0.3rem">
        <van-col span="12"></van-col>

        <van-col span="12" style="text-align: right">
          <van-popover
            v-model="showPopover"
            trigger="click"
            :actions="actions"
            @select="onSelect"
            :offset="[0, 20]"
            placement="bottom"
          >
            <template #reference>
              {{ query1.report_type == "0" ? "日报" : "周报" }}
              <van-icon name="arrow-down" />
            </template>
          </van-popover>
        </van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh1">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_reports"
        >
          <div
            class="report-item-container"
            style="margin-bottom: 0.6rem"
            v-for="(item, index) in list"
            :key="index"
            @click="onReportClick(item)"
          >
            <van-row class="report-item">
              <van-col class="issue-item-title" span="8">
                {{ item.report_type_text }}
              </van-col>
              <van-col class="issue-item-date" span="16">
                {{ item.submit_time }}
              </van-col>
            </van-row>
            <van-row class="report-item">
              <van-col
                class="issue-item-title-read van-ellipsis text-left"
                span="12"
              >
                {{
                  `今日带团数${item.lists_count},共计${item.tourists_count}人/次`
                }}
              </van-col>
              <van-col
                class="issue-item-title-read van-ellipsis text-right"
                span="12"
              >
                {{ `紧急情况上报:${item.emergency_reports_count}` }}
              </van-col>
            </van-row>
            <van-row class="report-item">
              <van-col
                class="issue-item-title-read van-ellipsis text-left"
                span="12"
              >
                {{ `上报人:${item.user_name}` }}
              </van-col>
              <van-col
                class="issue-item-title-read van-ellipsis text-right"
                span="12"
              >
                <van-tag
                  plain
                  type="primary"
                  v-if="item.reportable_type == 'TouristOffice'"
                  >旅行社</van-tag
                >
                <van-tag plain type="primary" v-else>导游</van-tag>
              </van-col>
            </van-row>
          </div>
        </van-list>
        <van-empty
          v-if="list.length == 0 && loading == false"
          description="暂无数据"
        />
      </van-pull-refresh>
    </div>
    <div class="message-container" v-if="query.notification_type == '1,2,5'">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_notifications"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            @click="onIssueItemClick(item)"
            :class="[
              item.relation.is_read &&
              item.notification.sender_or_receiver == 'receiver'
                ? 'issue-item-read'
                : 'issue-item',
            ]"
          >
            <van-row>
              <van-col span="14">
                <div
                  :class="[
                    item.relation.is_read &&
                    item.notification.sender_or_receiver == 'receiver'
                      ? 'issue-item-title-read van-ellipsis'
                      : 'issue-item-title van-ellipsis',
                  ]"
                >
                  <svg-icon
                    v-if="item.notification.sender_or_receiver == 'sender'"
                    :data_iconName="'sender'"
                    :className="'sender_icon'"
                  /><svg-icon
                    v-if="item.notification.sender_or_receiver == 'receiver'"
                    :data_iconName="'receiver'"
                    :className="'sender_icon'"
                  />{{
                    item.notification.title
                      ? item.notification.title
                      : "没有标题"
                  }}
                </div></van-col
              >
              <van-col span="10">
                <div
                  :class="[
                    item.relation.is_read &&
                    item.notification.sender_or_receiver == 'receiver'
                      ? 'issue-item-date text-read'
                      : 'issue-item-date',
                  ]"
                >
                  {{ item.notification.created_at }}
                </div>
              </van-col>
            </van-row>
            <div
              v-if="
                !item.relation.is_read &&
                item.notification.sender_or_receiver == 'receiver'
              "
              :class="[
                item.relation.is_read &&
                item.notification.sender_or_receiver == 'receiver'
                  ? 'dot background-read'
                  : 'dot',
              ]"
            ></div>
          </div>
        </van-list>
        <van-empty
          v-if="list.length == 0 && loading == false"
          description="暂无数据"
        />
      </van-pull-refresh>
    </div>
    <!-- 奖补 -->
    <div class="message-container" v-if="query.notification_type == '6'">
      <van-pull-refresh v-model="refreshing" @refresh="refreshGift">
        <van-list
          v-if="jiangbuList.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadJianbuPage"
        >
          <div
            v-for="(item, index) in jiangbuList"
            :key="index"
            @click="onMessageClicktwo(item)"
            :class="[item.is_read ? 'issue-item' : 'issue-item']"
          >
            <van-row>
              <van-col span="14">
                <div
                  :class="[
                    item.is_read
                      ? 'issue-item-title van-ellipsis'
                      : 'issue-item-title van-ellipsis',
                  ]"
                >
                  <!-- <svg-icon
                    v-if="item.notification.sender_or_receiver == 'sender'"
                    :data_iconName="'sender'"
                    :className="'sender_icon'"
                  />
                  <svg-icon
                    v-if="item.notification.sender_or_receiver == 'receiver'"
                    :data_iconName="'receiver'"
                    :className="'sender_icon'"
                  /> -->
                  {{ item.jiang_bu.title ? item.jiang_bu.title : "没有标题" }}
                </div>
              </van-col>
              <van-col span="10">
                <div
                  :class="[
                    item.is_read ? 'issue-item-date' : 'issue-item-date',
                  ]"
                >
                  {{ item.created_at }}
                </div>
              </van-col>
            </van-row>
            <div v-if="!item.is_read" :class="['dot']"></div>
          </div>
        </van-list>
        <van-empty
          v-if="jiangbuList.length == 0 && loading == false"
          description="暂无数据"
        />
      </van-pull-refresh>
      <!-- <div class="action_bg">
        <van-button
          type="info"
          class="action_btn"
          color="#395EE8"
          @click="toJiangbuList()"
          >申请</van-button
        >
      </div> -->
    </div>
    <Tarbar :active="4"></Tarbar>
  </div>
</template>

<script>
import api from "@/services/apis.js";
import Tarbar from "../components/Tarbar.vue";

export default {
  name: "message",
  components: { Tarbar },
  data() {
    const _self = this;
    return {
      actions: [
        { text: "日报", report_type: "0" },
        { text: "周报", report_type: "1" },
      ],
      showPopover: false,
      list: [],
      active: 0,
      loading: true,
      finished: false,
      refreshing: false,
      page_status: "",
      query: {
        page: 1,
        notification_type: _self.$route.query.type
          ? _self.$route.query.type
          : "1,2,5",
      },
      query1: {
        report_type: "0",
        page: 1,
      },
      tabs: [
        {
          id: 2,
          text: "系统",
          type: "1,2,5",
        },
        {
          id: 0,
          text: "出团",
          type: "3",
        },
        {
          id: 2,
          text: "日报/周报",
          type: "4",
        },
        {
          id: 3,
          text: "奖补",
          // notification_type - 查询类型
          type: "6",
        },
      ],

      // 奖补相关
      paging: {
        total_count: 0,
        total_pages: 0,
        current_page: 1,
        next_page: null,
      },
      jiangbuList: [],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    onTabClick(item) {
      if (item.type == "4") {
        this.query.notification_type = item.type;
        this.onRefresh1();
      } else if (item.type == "6") {
        this.query.notification_type = item.type;
        // 更新对应列表数据
        this.refreshGift();
      } else {
        this.query.notification_type = item.type;
        this.onRefresh();
      }
    },
    onIssueClick() {
      this.$router.push({
        path: "/message/issue",
        query: { type: this.query.notification_type },
      });
    },
    onReportClick(item) {
      this.$router.push({
        path: "/dailyWeekly/item",
        query: {
          id: item.id,
          type: this.query.notification_type,
        },
      });
    },
    onSelect(item) {
      this.query1.report_type = item.report_type;
      this.onRefresh1();
    },
    onIssueItemClick(item) {
      this.$router.push({
        path: "/message/item",
        query: {
          id: item.relation.id,
          type: this.query.notification_type,
          sender_or_receiver: item.notification.sender_or_receiver,
        },
      });
    },
    onDailyWeeklyClick() {
      this.$router.push({
        path: "/dailyWeekly",
        query: { type: this.query.notification_type },
      });
    },
    onEmergenciesClick() {
      this.$router.push({
        path: "/message/emergencies-item",
        query: {
          id: 1,
        },
      });
    },
    onItemClick(item) {
      this.$router.push({
        path: "/tuan/item",
        query: {
          id: 1,
        },
      });
    },
    async get_notifications() {
      const res = await this.$apis.get_notifications(this.query);
      if (res.status == 403) {
        this.page_status = 403;
        return false;
      } else {
        this.page_status = 200;
      }
      if (res.next_page) {
        this.query.page++;
        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      this.list = [...this.list, ...res.data];
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_notifications();
    },
    async get_reports() {
      const res = await this.$apis.get_reports(this.query1);
      if (res.status == 403) {
        this.page_status = 403;
        return false;
      } else {
        this.page_status = 200;
      }
      if (res.next_page) {
        this.query1.page++;
        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      this.list = [...this.list, ...res.data];
    },
    onRefresh1() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query1.page = 1;
      this.loading = true;
      this.get_reports();
    },
    onRefresh6() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query1.page = 1;
      this.loading = true;
      // 获取奖补对应列表数据
      this.get_notifications();
    },
    // 获取奖补数据
    async refreshGift() {
      this.finished = false;
      this.loading = true;
      const _self = this;
      let params = {
        // status:"approving","approve_pass","approve_deny",
      };
      // let res = await api.get_applyjiangbu_list(params);
      let res = await api.get_jiangbu_list(params);
      console.log("zzz", res.data);

      if (res.status == 200) {
        // alert(0);
        this.jiangbuList = res.data;
        _self.loading = false;
        _self.fillPagingtwo(res);
      }
      console.log("奖补列表", this.jiangbuList);
    },
    loadJianbuPage() {
      this.finished = false;
      this.loading = true;
      const _self = this;
      if (this.paging.next_page != null) {
        let params = {
          //   'status':["approving","approve_pass","approve_deny"],
          page: this.paging.next_page,
        };
        api.get_jiangbu_list(params).then((res) => {
          if (res.code === 200) {
            res.data.forEach((ele) => {
              this.jiangbuList.push(ele);
            });
            _self.loading = false;
            _self.fillPagingtwo(res);
          }
        });
      }
    },
    fillPagingtwo(res) {
      this.paging.total_count = res.page.total_count;
      this.paging.total_pages = res.page.total_pages;
      this.paging.next_page = res.page.next_page;
      this.paging.current_page = res.page.current_page;
      if (res.page.next_page) {
        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
    },
    onMessageClicktwo(item) {
      this.$router.push({
        path: `/message/jiangbuinfo`,
        query: { id: item.jiang_bu.id },
      });
    },
    // 跳转可以申请的奖补列表
    toJiangbuList() {
      this.$router.push("/message/jiangbuList");
    },
    // ---end--
  },
  created() {
    if (this.$route.query.notification_type == "6") {
      this.query.notification_type = this.$route.query.notification_type;
    }
    if (this.query.notification_type == "4") {
      this.onRefresh1();
    } else if (this.query.notification_type == "6") {
      this.refreshGift();
    } else {
      this.onRefresh();
    }
  },
};
</script>

<style scoped>
#message {
  height: 100vh;
  padding-top: 20vh;
}

.action_btn {
  position: fixed;
  bottom: 70px;
  border-radius: 25px;
  width: 95%;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  position: fixed;
  bottom: 0px;
  height: 130px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
}

.header {
  height: 20vh;
  line-height: 10vh;
  padding: 0 0.3rem;
  background: #6c94f6;
  border-bottom-left-radius: 1rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.message-container {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.message-item {
  background: #ffffff;
  height: 1.5rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.3rem;
  position: relative;
  margin-bottom: 10px;
}
.message-item-title {
  position: absolute;
  top: 0.3rem;
  left: 0.4rem;
  display: inline;
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
.message-item-body {
  font-size: 14px;
  color: #666666;
  position: absolute;
  top: 0.8rem;
  left: 0.4rem;
}
.message-item-date {
  font-size: 12px;
  color: #999999;
  position: absolute;
  top: 0.3rem;
  right: 0.4rem;
}
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.report-item-container {
  background: #fff;
  padding: 0.2rem 0.4rem;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
}
/* .tuan-info {
  margin-top: 1.6rem;
} */
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.status-btn {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: rgba(173, 173, 173, 0.2);
  color: #c1c1c1;
  padding: 2px 0.2rem;
}

.issue-item {
  background: #ffffff;
  height: 1rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  line-height: 1rem;
  padding: 0 0.3rem;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.issue-item-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.issue-item-body {
  font-size: 14px;
  color: #666666;
  position: absolute;
  top: 0.8rem;
  left: 0.4rem;
}
.issue-item-date {
  font-size: 12px;
  color: #999999;
  text-align: right;
}
.dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #f04134;
  border-radius: 50%;
  top: 0.2rem;
  right: 0.1rem;
}
.message-content {
  border-top: 1px dashed #ebedf0;
  margin-top: 20px;
}
.message-content-title {
  font-size: 14px;
  color: #333333;
  height: 0.8rem;
  line-height: 0.8rem;
  font-weight: bold;
}
.message-content-name {
  font-size: 14px;
  color: #333333;
  height: 0.5rem;
  line-height: 0.5rem;
  font-weight: bold;
}
.message-content-date {
  font-size: 12px;
  color: #666666;
  text-align: right;
}
.message-content-detail {
  font-size: 12px;
  color: #666666;
  text-align: justify;
}
.message-time {
  position: absolute;
  top: -0.4rem;
  left: 0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  width: 100%;
}
.edit-icon {
  font-size: 0.6rem;
}
.issue-item-read {
  background: #f1f1f1;
  height: 1rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  line-height: 1rem;
  padding: 0 0.3rem;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.issue-item-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.issue-item-title-read {
  color: #8c92a4;
  font-size: 12px;
  font-weight: bold;
}
.text-read {
  color: #8c92a4 !important;
}
.background-read {
  background-color: #8c92a4 !important;
}
.report-item {
  margin-bottom: 12px;
  /* background: #6c94f6 */
}
.van-radio__label {
  font-size: 0.2rem !important;
  color: #fff !important;
}
</style>
